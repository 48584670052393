import { Options, Vue } from 'vue-class-component';
import Navigation from '@/components/Navigation/Navigation.vue';
import UpcomingShows from '@/components/UpcomingShows/UpcomingShows.vue';
import SocialsAndBooking from '@/components/SocialsAndBooking/SocialsAndBooking.vue';

@Options({
    components: {
        Navigation,
        UpcomingShows,
        SocialsAndBooking,
    },
})
export default class Home extends Vue {
    private showSocials = false;

    public mounted(): void {
        window.setTimeout(() => {
            this.showSocials = true;
        }, 1000);
    }
}
