import { Vue, Options } from 'vue-class-component';
import { initializeApp } from 'firebase/app';
import { useStore } from 'vuex';
import Navigation from './components/Navigation/Navigation.vue';
import CookieConsent from './components/CookieConsent/CookieConsent.vue';
// import { event } from 'vue-gtag';

@Options({
    components: {
        Navigation,
        CookieConsent,
    },
})
export default class App extends Vue {
    private showShareButton = false;
    private store = useStore();
    private musicItems = [
        {
            icon: 'spotify.svg',
            title: 'Spotify',
            link: 'https://open.spotify.com/track/2ZNYGG2KohHo9rT1MATepy',
        },
        {
            icon: 'apple.svg',
            title: 'Apple Music',
            link: 'https://music.apple.com/ch/album/forever-young/1652177665?i=1652177666',
        },
        // {
        //     icon: 'itunes.svg',
        //     title: 'iTunes',
        //     link: 'https://open.spotify.com/track/2z6npC2l0uzhzXaPIDeb5o?si=74586a8a22db4774',
        // },
        // {
        //     icon: 'amazon.svg',
        //     title: 'Amazon',
        //     link: 'https://open.spotify.com/track/2z6npC2l0uzhzXaPIDeb5o?si=74586a8a22db4774',
        // },
        // {
        //     icon: 'pandora.svg',
        //     title: 'Pandora',
        //     link: 'https://open.spotify.com/track/2z6npC2l0uzhzXaPIDeb5o?si=74586a8a22db4774',
        // },
        {
            icon: 'youtube.svg',
            title: 'YouTube',
            link: 'https://music.youtube.com/playlist?list=OLAK5uy_mGv6yBhk5pAwkAXk8qHjgLlnxBE8i5NYM',
        },
        {
            icon: 'tidal.svg',
            title: 'Tidal',
            link: 'https://listen.tidal.com/track/257222375',
        },
        {
            icon: 'deezer.svg',
            title: 'Deezer',
            link: 'https://www.deezer.com/en/track/1991253617',
        },
        {
            icon: 'napster.svg',
            title: 'Napster',
            link: 'https://play.napster.com/track/tra.715173059',
        },
        {
            icon: 'anghami.png',
            title: 'Anghami',
            link: 'https://play.anghami.com/song/1083516993?refer=linktree',
        },
        {
            icon: 'boomplay.jpg',
            title: 'Boomplay',
            link: 'https://www.boomplay.com/songs/106659005',
        },
    ];

    private socials = [
        {
            icon: 'facebook.png',
            title: 'Facebook',
            link: 'https://www.facebook.com/rudetins',
        },
        {
            icon: 'instagram.png',
            title: 'Instagram',
            link: 'https://www.instagram.com/rudetinsofficial/',
        },
        {
            icon: 'youtube.png',
            title: 'YouTube',
            link: 'https://www.youtube.com/channel/UCbrACoC56TvUUSoRpd9MNNA',
        },
        {
            icon: 'spotify.png',
            title: 'Spotify',
            link: 'https://open.spotify.com/artist/20XsDMN8RhkHSmnH2DrgAz',
        },
        {
            icon: 'soundcloud.png',
            title: 'Soundcloud',
            link: 'https://soundcloud.com/rudetins',
        },
    ];

    public async mounted(): Promise<void> {
        this.getBrowserLang();

        const firebaseConfig = {
            apiKey: 'AIzaSyD5mqkSNHjDoCbx6pwRxsFA-8Rm9GnZIRE',
            authDomain: 'rudetins-webpage.firebaseapp.com',
            projectId: 'rudetins-webpage',
            storageBucket: 'rudetins-webpage.appspot.com',
            messagingSenderId: '1022344407835',
            appId: '1:1022344407835:web:c2a84b83aa60ce35cb01be',
            measurementId: 'G-1RJPTBL3JW',
        };

        const app = initializeApp(firebaseConfig);
        this.store.state.firebaseApp = app;

        if (navigator.share && this.isMobile()) {
            this.showShareButton = true;
        }
    }

    private openExternalLink(link: string) {
        // event('clicked-externalLink-Nav', { link: link });
        window.open(link, '_blank');
    }

    private getBrowserLang(): void {
        let lang = '';
        const lsLang = localStorage.getItem('rt-lang');

        if (lsLang) {
            lang = lsLang;
        } else {
            const navLang = navigator.language;

            if (navLang.length != 2) {
                lang = navLang.split('-')[0];
            } else {
                lang = navLang;
            }
        }

        // ToDo: Switch back when we have english texts
        // if (lang == 'en') {
        //     this.$i18n.locale = 'en';
        // } else {
        //     this.$i18n.locale = 'de';
        // }

        this.$i18n.locale = 'de';
    }

    private showMenu(): void {
        this.store.state.showMenu = true;
    }

    private isHome(): boolean {
        if (this.$route.name == 'Home') return true;

        return false;
    }

    private sharePage() {
        if (navigator.share) {
            navigator
                .share({
                    title: 'Check out Forever Young by RudeTins!',
                    url: 'https://foreveryoung.rudetins.ch/',
                })
                .then(() => {
                    console.log('Thanks for sharing!');
                })
                .catch(console.error);
        } else {
            // fallback
            console.log('share api not working');
        }
    }

    private isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    }
}
