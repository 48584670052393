import { Options, Vue } from 'vue-class-component';
// import { event } from 'vue-gtag';
@Options({})
export default class CookieConsent extends Vue {
    private showConsent = true;

    private acceptCookie(): void {
        //event('clicked-CookieConsent', { accept: true });
        this.showConsent = false;
    }
}
