import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import installI18n from './lang/index';
// import VueGtag from 'vue-gtag';

const app = createApp(App);
installI18n(app);

const vueGtagConfig = {
    config: { id: 'G-S27XZQP4QX' },
};

// app.use(store).use(router).use(VueGtag, vueGtagConfig).mount('#app');
app.use(store).use(router).mount('#app');
