export default {
    apot: {
        title: 'Hör Forever Young auf den folgenden Plattformen',
    },
    cookie: {
        text: 'Wir nutzen Cookies & Google Analytics um dir die bestmögliche Browsing-Erfahrung zu bieten. Die mit Hilfe von Cookies & Google Analytics gesammelten Daten werden zur Optimierung unserer Webseite genutzt.',
    },
    misc: {
        accept: 'Ok',
    },
};
